.cardBorder[data-v-da251eee] {
  width: 170px;
  height: 170px;
  border: 1px dashed #DDD;
  border-radius: 10px;
  cursor: pointer;
  line-height: 170px;
  text-align: center;
  color: #8c939d;
  font-size: 28px;
}
.cardBorder[data-v-da251eee]:hover {
  border: 1px dashed #2979FF;
}
.imgBox[data-v-da251eee] {
  width: 170px;
  height: 170px;
  position: relative;
  border-radius: 10px;
  overflow: hidden;
  cursor: pointer;
}
.maskBox[data-v-da251eee] {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
  top: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  color: #FFFFFF;
  font-size: 22px;
}
.maskBox i[data-v-da251eee] {
  line-height: 170px;
  width: 30px;
}
.maskBox i[data-v-da251eee]:hover {
  color: #2979FF;
}
.imgBox img[data-v-da251eee] {
  width: 100%;
  height: 100%;
}